import React from "react"
import { graphql } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Configurations from "src/components/Product/Configurations"

const ArchiveGarageDoorConfigurations = ({
  data: { allPrismicConfigurations, allPrismicBlogPost },
}) => {
  const data = allPrismicConfigurations
  const blogsData = allPrismicBlogPost

  const crumbs = [
    { label: "Garage Doors", href: "/garage-doors" },
    {
      label: "Door Configurations",
      href: "/garage-doors/configurations",
      active: true,
    },
  ]

  return (
    <main>
      <Seo title={"Garage Door Configurations | Garage Door Types"} 
        description={
          "Our extensive choice of Garage door configurations enables you to find a door to perfectly suit the needs of your space."
        } />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <Configurations {...data} blogsData={blogsData} type={"garage door"} />
    </main>
  )
}

export default ArchiveGarageDoorConfigurations

export const garageDoorConfigQuery = graphql`
  query GarageDoorConfig {
    allPrismicConfigurations(
      filter: { data: { configuration_type: { eq: "Garage Door" } } }
    ) {
      edges {
        node {
          data {
            configuration_type
            title {
              text
            }
            link {
              url: uid
            }
            featured_image {
              url
              alt
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            featured_video {
              url
            }
            door_image {
              url
              alt
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      limit: 2
      filter: { tags: { eq: "Garage Doors" } }
      sort: { order: DESC, fields: data___post_date }
    ) {
      edges {
        node {
          uid
          type
          tags
          data {
            post_date(formatString: "DD MMM YYYY")
            title {
              text
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              richText
            }
          }
        }
      }
    }
  }
`
